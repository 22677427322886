import React from 'react';
import Form from './Form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import content from '../content';

export default function Contact() {
  return (
    <div>
        <div className="min-h-screen flex bg- flex-col items-center justify-center -apple-system" id="contact" >
          <div className="grid grid-cols-3 grid-row-3 mx-auto gap-x-24 mb-20 mt-20" >

              <div className={"text-center md:text-left  font-bold col-span-3 md:col-span-1 md:ml-0 mb-6 md:mb-0"} >
                  <img className="h-40 w-40 mx-auto md:mx-2  md:mt-4 md:mb-4 rounded-full "  src={content.contact.pic} ></img>
                  <h1 Style={"font-size: 2.58em;"}>{content.contact.name}</h1>
                  <h1 className={"text-4xl"}>{content.contact.location}</h1>
                  <h1 className={"text-xl"}>At your Local Coffee shop.</h1>
                  <h1 className={"text-lg"}>(post pandemic)</h1>
                  <h1 className={"text-2xl"}>{content.contact.email}</h1>
                  <h1 className={"text-2xl"}>{content.contact.phone}</h1>
              </div>

              <div className={"border-solid border-4 border-black rounded-xl  col-span-3 md:col-span-2 border-soild"} >
              <h1 className="text-5xl font-bold text-center">Contact Me.</h1>
                  <Form/>
              </div>

          </div>
          <div  className={"text-center mt-0 md:mt-10  font-bold text-xl grid grid-cols-3"}>
            <a href={content.contact.gitLink}><img className={"h-10 w-10 m-4 transform scale-100 hover:scale-125"} src={content.contact.gitImg}></img></a>
            <a href={content.contact.linkedinLink}><img className={"h-10 w-10 m-4 transform scale-100 hover:scale-125"} src={content.contact.linkedinImg}></img></a>
            <a href={content.contact.instaLink}><img className={"h-10 w-10 m-4 transform scale-100 hover:scale-125"} src={content.contact.instaImg}></img></a>
        </div>

        </div>
        
    </div>
  );
}