import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import content from '../content';

const webLiveStyle = "ml-auto w-16 h-16 bg-white shadow hover:shadow-lg rounded-full focus:scale-95 transform scale-100 hover:scale-110"
const webDownStyle = "opacity-50 ml-auto w-16 h-16 bg-white shadow rounded-full"
const gitDownStyle = "opacity-50 mx-auto w-16 h-16 bg-white shadow rounded-full ";
const gitUpStyle = "mx-auto w-16 h-16 bg-white shadow hover:shadow-lg rounded-full focus:scale-95 transform scale-100 hover:scale-110";
export default function Project() {
  return (
    <div
      className="min-h-screen  flex flex-col items-center justify-center -apple-system"
      id="portfolio"
      Style={"background-image: linear-gradient( #1c1e1f, #8e9294);"}
    >
      <h1 className="text-5xl font-bold text-gray-200 mt-4 mb-10">Portfolio</h1>
      <div className={"grid grid-cols-4  mx-auto"}>
      {content.portfolio.projects.map((project, index) => {
            return (
              <div className="col-span-4 md:col-span-1">
                <a href={project.youtube}><img className={"w-11/12 h-1/4 mx-auto shadow-2xl rounded-lg"} key={index} src={project.gif}></img></a>
                <h1 className={"m-4 text-2xl font-bold italic text-center"}>{project.title}</h1>
                <p className={"text-center m-2 pb-4 text-gray-900"} >{project.bio}</p>
                <div className={"grid grid-cols-3 mb-8"}>
                <a title={(project.link === "") ? "Live website is no longer hosted": "Live Demo"} className={(project.link === "") ? webDownStyle : webLiveStyle} href={(project.link === "") ? null : project.link} >
                    <div className={"p-4"}>
                      <img className={" "} src={content.portfolio.live} ></img>
                      
                    </div>
                  </a>
                  <a title={(project.git === "") ? "Github is private": "Github"} className={(project.git === "") ? gitDownStyle : gitUpStyle} href={(project.git === "") ? null : project.git} >
                    <div className={"p-4"}>
                      <img className={" "} src={content.portfolio.github} ></img>
                      
                    </div>
                  </a>
                  <a title="Youtube Demo" className={"mr-auto w-16 h-16 bg-white shadow hover:shadow-lg rounded-full focus:scale-95 transform scale-100 hover:scale-110"} href={project.youtube} >
                    <div className={"p-4"}>
                      <img className={" "} src={content.portfolio.youtube} ></img>
                    </div>
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}