import React, {useState} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import content from '../content';

export default function About() {

    const [more, setMore] = useState(false);

    const toggle = () => {
        setMore(!more);
    }

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center -apple-system"
      id="about"
      Style={"background-image: linear-gradient(to bottom right,  #1c1e1f, #636362);"}
    >
        <div className={"grid grid-cols-12 gap-x-16 md:w-11/12 w-auto"} >
            <div className={"md:col-span-3 col-span-12 flex flex-col items-center"}>
                <h1 className="text-5xl text-center text-gray-200 font-bold">About</h1>
                <div className="flex flex-wrap justify-center mt-4">
                    <img className="h-60 w-60 mx-auto md:mx-2  md:mt-4 md:mb-4 rounded-full " src={content.about.pic}></img>
                </div>
            </div>
            <div className={"md:col-span-9 col-span-12 flex flex-col items-center "}>
                <p className="md:w-10/12 w-5/12 text-xl text-center my-10">
                    {content.about.bio}
                </p>
                <p className={`${ more ? "" : "hidden"} md:w-10/12 w-5/12 text-xl text-center my-10`}>
                    {content.about.bioLong}
                </p>
                <div className={"inline-flex"} >
                    <button  className=" px-10 py-1 text-md shadow-xl font-bold bg-red-500 mx-4 my-2 mb-8 uppercase rounded-lg" onClick={toggle} >{more ? "Less" : "More"}</button>
                    <a href={process.env.PUBLIC_URL + '/assets/res.pdf'}  className=" px-10 py-1 text-md shadow-xl font-bold bg-red-500 mx-4 my-2 mb-8 uppercase rounded-lg">Resume</a>
                </div>
            </div>
        </div>
    </div>
  );
}