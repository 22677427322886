import React from 'react';


export default class extends React.Component {
  constructor(props) {
	super(props);
	this.state = { feedback: '', name: 'Name', email: '', success: false, noInput: false, emailValid: false,};
	this.handleChange = this.handleChange.bind(this);
	this.handleChangeTwo = this.handleChangeTwo.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
	this.handleSuccess = this.handleSuccess.bind(this);
  }

  render() {
	return (
	this.state.success ? 
	<div  className={"mt-32 text-center font-bold  -apple-system"}>
		<h1 Style={"font-size: 3em;"} >Success</h1>
		<h1 Style={"font-size: 1.5em;"}>Looking forward to our first meeting.</h1>
	</div> :
  	<form className="test-mailing text-center p-4 -apple-system ">
    	
    	<div>
		<label>
  		  <input className={"w-full mt-4 p-2 shadow-xl"} type="text" name="name" onChange={this.handleChangeTwo} value={this.state.email} placeholder="Your email here."/>
  		</label>
		<p className={`${this.state.noInput ? "" : "hidden"} text-sm text-red-700`} >Please enter an email and a message</p>
		<p className={`${this.state.emailValid ? "" : "hidden"} text-sm text-red-700`} >Please enter a vaild email</p>
      	<textarea
        	id="service_aqn5x4e"
        	name="test-mailing"
        	onChange={this.handleChange}
        	placeholder="Hey there. Lets get in touch!"
        	required
        	value={this.state.feedback}
			className={"w-full mt-4 p-2 shadow-xl"}
			Style={"height: 10em"}
			
      	/>
    	</div>
    	<div>
			<button  className="shadow-xl animate-float bg-gray-300 px-10 py-3 text-xl font-bold uppercase mt-10 rounded-lg" onClick={this.handleSubmit} >Submit</button>
		</div>
  	</form>
	)
  }

  handleChange(event) {
    this.setState({feedback: event.target.value})
  }

  handleChangeTwo(event) {
    this.setState({email: event.target.value})
  }

  handleSubmit (event) {
	event.preventDefault();
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const templateId = 'template_6sdglqi';
	if( this.state.feedback == '' || this.state.email == ''  ){
		this.setState({noInput: true})
	}

	else if(!re.test(String(this.state.email).toLowerCase())  ){
		this.setState({emailValid: true})
		
	} else{
		this.sendFeedback(templateId, {message: this.state.feedback, from_name: this.state.name, reply_to: this.state.email})
	}
  }

  handleSuccess(){
	this.setState({success: true})
  }

  sendFeedback (templateId, variables) {
	window.emailjs.send(
  	'service_aqn5x4e', templateId,
  	variables
  	).then(res => {
		console.log('Email successfully sent!')
		this.handleSuccess()
  	})
  	// Handle errors here however you like, or use a React error boundary
  	.catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
  }
}