import React, { useEffect, useState } from 'react';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { Link as ScrollLink } from 'react-scroll';
import Typical from 'react-typical';
import content from '../content';
import './hover.css';

export default function Navigation() {

  const [isTop, setTop] = useState(false);
  const [animated, setAnimated] = useState(false);
  const [page, setPage] = useState('home');
  const [isTooSmall, setTooSmall] = useState(false);
  const height = window.innerHeight;
  const width = window.screen.width;


  const changeBackground = () => {
    
    if(window.scrollY > 70){
      setTop(true)
    } else {
      setTop(false)
    }
    if(height > window.scrollY){setPage("Home")}
    else if(height < window.scrollY && (height*2) > window.scrollY){setPage("About")}
    else if((height*2) < window.scrollY && (height*3) > window.scrollY){setPage("Stack")}
    else if((height*3) < window.scrollY && (height*4) > window.scrollY){setPage("Portfolio")}
    else if((height*4) < window.scrollY && (height*5) > window.scrollY){setPage("Contact")}
    if(window.innerWidth <= 600){
      setTooSmall(true)
    }else{
      setTooSmall(false)
    }
  }

  
  useEffect(() => {
    if(window.innerWidth <= 600){
      setTooSmall(true)
    }
    setAnimated(true);
  }, []);

  window.addEventListener('scroll', changeBackground)

  return (

    <div className={`${ isTop ? 'bg-black' : 'bg-white' } sticky top-0 z-50  transition duration-200 ease-in-out`}>
      <div className={`${ isTop ? 'text-white' : 'text-black' } ${ isTooSmall ? "text-center" : ""}flex items-center justify-between w-10/12 mx-auto py-3 transition duration-200 -apple-system`}>
        <h1 className="text-3xl font-bold">
            <ScrollLink to={'home'} smooth={true}>
              <h1 className={"inline-block hover:" + "text-red-500 " + "mx-2"}>{page}</h1>
              <span className={`${isTooSmall ? "hidden" : "inline-block"} w-3 h-3 bg-red-500 inline-block rounded-full`}></span>
            </ScrollLink>
        </h1>
        <div className={isTooSmall ? "hidden" : "inline-block"}>
          {content.nav.links.map((link, index) => {
            return (
              <span key={index} className="text-xl  mr-4">
                <ScrollLink to={link.to} smooth={true}>
                  <button className="transform hover:scale-110  ">
                    {link.text}
                  </button>
                </ScrollLink>
              </span>
            );
          })}
        </div>
      </div>
      <a href={content.portfolio.projects[0].link} Style={(animated && !isTop) ? "top: 10em; left: 100em;  transform: translateX(-35em) translateX(-10em) rotate(-35deg) " :"left: 100em;"} className={`${isTooSmall ? "hidden" : "" } fixed w-1/3 h-5/12 transition duration-1000 ease-in-out pichover`} >
        <img className={""} src={content.nav.milkcreate}></img>
      </a>
      <a href={content.portfolio.projects[1].youtube} Style={(animated && !isTop) ? "top: 15em; left: 100em;  transform: translateX(-45em) translateX(-15em) rotate(-45deg) " :"left: 100em;"} className={`${isTooSmall ? "hidden" : "" } fixed w-1/3 h-5/12 transition duration-1000 ease-in-out pichover`} >
        <img className={""} src={content.nav.pistil}></img>
      </a>
      <a href={content.portfolio.projects[2].youtube} Style={(animated && !isTop) ? "top: 20em; left: 100em;  transform: translateX(-50em) translateX(-20em) rotate(-55deg) " :"left: 100em;"} className={`${isTooSmall ? "hidden" : "" } fixed w-1/3 h-5/12 transition duration-1000 ease-in-out pichover`} >
        <img className={""} src={content.nav.highlite}></img>
      </a>
      
    </div>
  );
}
